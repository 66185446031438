<template>
	<v-list-item @click="$emit('selectPointCard', pointCard)">
		<v-list-item-content>
			<v-list-item-title :class="pointCard.remoteID ? '' : 'font-italic' ">
				{{ pointCard.remoteID ? pointCard.remoteID : $t('codeRequired') }}
			</v-list-item-title>
			<v-list-item-subtitle v-if="pointCard.cardName === ''"><span>{{ customer.name }}  &mdash; Point card</span></v-list-item-subtitle>
			<v-list-item-subtitle v-else><span>{{ customer.name }}  &mdash; {{ pointCard.cardName }}</span></v-list-item-subtitle>
		</v-list-item-content>
	</v-list-item>
</template>

<script>
export default {
	name: "PointCardListItem",
	props: {
		pointCard: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {

		}
	},
	computed: {
		customer() {
			if(!this.pointCard.customer || !this.pointCard.customer.address || !this.pointCard.customer.address.name_line) {
				return {name: this.$t('customerNameNA'), nameStyle:'font-italic'}
			}
			return {name: this.pointCard.customer.address.name_line, nameStyle:'text--primary'}
		}
	}
}
</script>

<style scoped>

</style>
