<template>
  <div>
    <div>
      <ActionList
        :preloaded-items-list="pointCards"
        :filters="filters"
        :actions="actions"
        :call-to-actions="callToActions"
        @actionSuccess="getPointCards"
        @actionFailure="getPointCards"
        :load-items-callback="loadItemsCallback"
        :fixed-filters="fixedFilters"
        :empty-text="$t('noFormsOfPaymentFound')"
        :import-data-values="importDataValues"
      >
        <template v-slot:item="{ item, index }">
          <PointCardListItem
            :key="item.uuid"
            :point-card="item"
            @selectPointCard="$emit('selectPointCard', item)"
          />
          <v-divider
            :key="index"
            inset
          />
        </template>
      </ActionList>
      <div v-intersect.quiet="loadMore" />
    </div>
  </div>
</template>

<script>
import ActionList from "@/components/common/list/ActionList.vue"
import PointCardListItem from "@/components/pointCards/PointCardListItem.vue"

export default {
	name: "PointCardList",
	components: {
		PointCardListItem,
		ActionList
	},
	props: {
		loadItemsCallback: {
			type: Function,
			default: () => null
		},
		fixedFilters: {
			type: Array,
			default: () => []
		},
		callToActions: {
			type: Array,
			default: () => []
		},
		pointCards: {
			type: Array,
			default: () => []
		}
	},
	data() {
		console.log('list')
		return {
			startIndex: 0,
			length: 50,
			step: 50,
			isLoadingMore: false,
			filters: [
				{
					key: 'amount',
					label: this.$t('amount'),
					type: 'number',
					value: null,
					operator: 'equals'
				},
				{
					key: 'created',
					label: this.$t('dateCreated'),
					type: 'datetime',
					value: [
						0,
						0
					],
					operator: 'between'
				},
				{
					key: 'customer.address.name_line',
					label: this.$t('customerName'),
					type: 'text',
					value: null,
					operator: 'contains'
				},
				{
					key: 'customer.email',
					label: 'Customer email',
					type: 'text',
					value: null,
					operator: 'contains'
				},
			]
		}
	},
	computed: {
		actions() {
			return []
		},
		importDataValues() {
			return {
				createCallback: this.createPointCard,
				updateCallback: null,
				defaultCreateObject: {

				},
				schema: [
					{
						property: 'remoteID',
						title: 'Code',
						required: true,
						type: 'String',
						defaultValue: null,
						value: null
					},
					{
						property: 'amount',
						title: 'Amount',
						required: true,
						type: 'Float',
						defaultValue: null,
						value: null
					},
					{
						property: 'item',
						title: 'Item',
						required: true,
						type: 'String',
						defaultValue: null,
						value: null
					},
					{
						property: 'expiryMonth',
						title: 'Expiry Month',
						required: true,
						type: 'String',
						defaultValue: null,
						value: null
					},
					{
						property: 'expiryYear',
						title: 'Expiry Year',
						required: true,
						type: 'String',
						defaultValue: null,
						value: null
					},
				]
			}
		}
	},
	methods: {
		loadMore() {
			this.length = this.length + this.step
		},
		createPointCard() {
			return this.$store.dispatch('getPointCards')
		},
		getPointCards() {
			return this.$store.dispatch('getPointCards')
		}
	}
}
</script>

<style scoped>

</style>
